<template>
  <div
    ref="modal"
    class="modal fade"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
  >
    <div
      class="
        modal-dialog modal-dialog-centered modal-lg modal-dialog-scrollable
      "
    >
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="staticBackdropLabel">{{ $t("deliveryStatusModal.deliveryStatus") }}</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            :aria-label="$t(`confirmModal.close`)"
          ></button>
        </div>
        <div class="modal-body">
          <div>
            <a
              :href="`${appUrl}/deliveries/tracking/${method}?code=${trackingCode}&_token=${token}`"
              target="_blank"
              class="btn btn-primary"
            >
              {{ $t("deliveryStatusModal.trackingPage") }}
            </a>
          </div>
          <div v-if="tracking === undefined">
            <Vue3Lottie
              :animationData="loadFileJSON"
              :height="160"
              :width="300"
              :key="key"
            />
          </div>
          <div v-else-if="tracking.length === 0" class="text-center">
            <Vue3Lottie
              :animationData="emptyJSON"
              :height="160"
              :width="300"
              :key="key"
            />
            <div class="bold-24 p-5">{{ $t("deliveryStatusModal.noStatusFound") }}</div>
          </div>
          <div class="timeline mt-5 me-4 text-start">
            <div
              class="timeline-item"
              v-for="track in tracking"
              :key="track.id"
            >
              <div class="timeline-time">{{ formatDate(track.date) }}</div>
              <div class="timeline-status">
                <div
                  class="timeline-status-icon dark bg-gradient"
                  :class="
                    deliveryTrackingStatuses[track.delivery_status]?.color
                  "
                >
                  <BaseIcon
                    :name="
                      deliveryTrackingStatuses[track.delivery_status]?.icon ??
                      delivery - info
                    "
                  />
                </div>
              </div>
              <div class="timeline-data">
                <h4 class="bold-19 mt-2">
                  {{ deliveryTrackingStatuses[track.delivery_status]?.name }}
                </h4>
                <div>{{ track.delivery_status_text }}</div>
                <div>{{ track.location }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import http from "../../modules/http";
import BaseIcon from "@/components/icons/BaseIcon";

import { Vue3Lottie } from "vue3-lottie";
import loadFileJSON from "../../assets/animations/loadFile.json";
import emptyJSON from "../../assets/animations/empty.json";
import DeliveryTrackingStatuses from "../../modules/DeliveryTrackingStatuses";
import { useStore } from "vuex";
import date from "../../modules/date";
import tenant from "../../modules/tenant";
const bootstrap = require("bootstrap");

export default {
  name: "DeliveryStatusModal",
  components: { BaseIcon, Vue3Lottie },
  props: {
    modelValue: Object,
    trackingCode: String,
    method: Number,
  },
  data() {
    return {
      store: useStore(),
      value: this.modelValue,
      key: 0,
      tracking: [],
      loadFileJSON: loadFileJSON,
      emptyJSON: emptyJSON,
      deliveryTrackingStatuses: DeliveryTrackingStatuses,
    };
  },
  watch: {
    modelValue() {
      this.key++;
      this.loadDeliveryStatus();
    },
  },
  computed: {
    appUrl() {
      return tenant.getApiBaseUrl();
    },
    token() {
      return this.store.state.token;
    },
  },
  methods: {
    formatDate(val) {
      return date.format(val, true, false);
    },
    showModal() {
      if (this.myModal === undefined) {
        this.myModal = new bootstrap.Modal(this.$refs.modal, {
          backdrop: "static",
        });
      }
      this.myModal.show();
      this.loadDeliveryStatus();
    },
    loadDeliveryStatus() {
      http
        .fetch(
          `/deliveries?method_id=${this.method}&tracking_code=${this.trackingCode}`
        )
        .then((data) => {
          this.tracking = data.tracking;
        });
    },
  },
  emits: ["update:modelValue", "change"],
};
</script>
